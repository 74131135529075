<template>
  <section class="homepage-section">
    <TwinColorTitle>
      Seamless for planners,<br><em>unforgettable for travelers</em>
    </TwinColorTitle>

    <div class="description">Experience the difference with TourHero. From seamless trip planning to unforgettable destinations, we make your travel dreams a reality.</div>

    <CustomerReviews :reviews/>
  </section>
</template>

<script setup>
import { ref } from "vue";
import TwinColorTitle from "@/components/Theme2/TwinColorTitle.vue";
import CustomerReviews from "@/components/CustomerReviews.vue";
import Allison from 'app/assets/images/mba/reviews/allison.jpg'
import Michelle from 'app/assets/images/home/michelle.jpg'
import Emery from 'app/assets/images/home/emery.jpg'

const reviews = ref([
  {
    position: -2,
    title: `Explore, Connect & Grow: 6-Day Yoga Retreat in France with Emery Bryant III`,
    description: `It was very personalized. We got to know our hosts and staff at The Frog's House.  I loved the small town feeling of St. Jeannet – it felt very safe, and was so easy to walk around and find restaurants and stores. The scenery in St. Jeannet was incredible.`,
    author: `Emery Bryant`,
    photo: Emery
  },
  {
    position: -1,
    title: `A Mystical Journey in Mexico: Riviera Maya Wellness Retreat with Michelle`,
    description: `TourHero made everything come together. I had a vision and they made it come to reality. Once on site, they were extremely responsive with any needs we had. The initial website they set up was beautiful and well-informed.`,
    author: `Michelle`,
    photo: Michelle
  },
  {
    position: 0,
    title: `A Three-Part Jordan Journey Across Aqaba, Wadi Rum and Petra`,
    description: `TourHero was beyond easy to work with! They helped me plan a trip to meet a diverse range of needs and we had a blast!`,
    author: `Allison Greenwald`,
    photo: Allison
  },
  {
    position: 1,
    title: `Treasures of the Sea: A Galapagos Journey with Abdulgadir Ahmed`,
    description: `The Galapagos was awesome. We some so many unique animals, had great snorkeling trips and hikes, and overall made so many memories.`,
    author: `Abdulgadir Ahmed`,
  },
  {
    position: 2,
    title: `Guatemala Yoga Retreat: an Empowering, Magical Adventure with Paisley`,
    description: `The ease of the arrangements, felt super safe with the car transportations. Also the customer service was out of the world, you guys were there for me when I needed it the MOST! Everything was perfect.`,
    author: `Paisley Parvati Devi`,
  },
]);
</script>

<style lang="scss" scoped>
section.homepage-section {
  @include main-rewrap;
  align-items: center;
  gap: 24px;
  padding-top: 64px;
  padding-bottom: 84px;
  overflow: hidden;

  .description {
    max-width: 600px;
    text-align: center;
    margin-bottom: 8px;
  }
}
</style>